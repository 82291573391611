import { Suspense } from 'react'
import { ClientOnly } from 'remix-utils/client-only'

const publishDate = (date: Date) =>
  date.toLocaleDateString('en-US', publishDateOptions)

const publishDateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

export const PublishDate = ({ date }: { date: Date }) => (
  <time dateTime={publishDate(date)}>
    <Suspense>
      <ClientOnly fallback={publishDate(date)}>
        {() => publishDate(date)}
      </ClientOnly>
    </Suspense>
  </time>
)
